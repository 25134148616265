import React, {useState, useEffect} from 'react'
import styled from 'styled-components'
import '../App.css';
//import ContactUs from './ContactUs';
import Header from '../components/header';
import Nav from '../components/nav';
import Footer from '../components/footer';
import ModalContact from '../components/modalcontact';
import {CardElementOne, CardElementTwo, CardElementThree} from '../components/card';
import {CardObjOne, CardObjTwo, CardObjThree} from '../components/card/cardData'
// import HeroImage from './pics/IMG_1730.JPG'
import HeroImage from '../pics/Hero_Image.png'
import HeroImageLow from '../pics/Hero_Image_Low.png'
// import Charlie from '../pics/Charlie.png'


const HeroContainer = styled.div`
  width: 100%;
  /* .59 is the ratio of height to width on the image */
  min-height: calc(100vw * .59);
`
const LowImg = styled.img`
  opacity: ${({highResLoaded})=> (highResLoaded ? '0' : '100%')};
`

const HighImg = styled.img`
  opacity: ${({highResLoaded})=> (!highResLoaded ? '0' : '100%')};
`

const HeroContent = styled.div`
position: absolute;
top: max(40vh, 300px);
width: 100%;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
@media (max-width: 780px) {
  top: max(30vh, 260px)
}
`
const HeroTitle = styled.h1`
max-width: 737px;
height: 123px;
margin-bottom: 20px;
padding: 0 10px;

font-family: Montserrat;
font-style: normal;
font-weight: 800;
font-size: 52px;
line-height: 63px;
text-align: center;
text-transform: uppercase;

color: #FEFEFE;

text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
@media (max-width: 780px) {
  font-size: 36px;
  line-height: 42px;
  height: auto;
}
@media (max-width: 520px) {
  font-size: 24px;
  line-height: 26px;
  height: auto;
}
`

const HeroSubTitle = styled.h2`
max-width: 600px;
height: auto;
margin-top: 100px;
margin-bottom: 10px;

font-family: Montserrat;
font-style: normal;
font-weight: 800;
font-size: 20px;
line-height: 24px;
text-align: center;
text-transform: uppercase;

color: #FFFFFF;
@media (max-width: 780px) {
  font-size: 16px;
  line-height: 20px;
  margin-top: 80px;
}
@media (max-width: 520px) {
  font-size: 14px;
  line-height: 16px;
  margin-top: 40px;
  height: auto;
}
/* check in ok with webowner */
@media (max-width: 420px) {
  display: none;
}
`
const ContactNowButton = styled.button`
width: 155.88px;
height: 42px;
font-family: Hind Madurai;
font-style: normal;
font-weight: normal;
font-size: 18px;
line-height: 25px;
color: #FFFFFF;
background: #E4A436;
border: 2px solid rgba(0, 0, 0, 0.2);
box-sizing: border-box;
border-radius: 7px;
`

const HowItWorksHeaderWrapper = styled.div`
background: #E4A436;
width: 100vw;
margin-bottom: 24px;
`
const HowItWorksHeader = styled.h2`
font-family: Montserrat;
font-style: normal;
font-weight: 800;
font-size: 36px;
line-height: 95px;
text-align: center;
text-transform: uppercase;
color: #FFF;

`
const StyledHowItWorks = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-gap: 40px;
  justify-content: space-around;
`
const AboutStyledList = styled.ul`
  text-align: left;
  font-family: Hind Madurai;
  font-weight: 500;
  font-size: 24px;
  line-height: 41px;
  margin: 0 30px;
  li {
    margin: 0px 10px;
  }
  /* First li gets no margin-top */
  li:not(:first-child) {
    margin: 10px;
    margin-top: 20px;
  }
  @media (max-width: 1100px) {
    margin:  0 20px 20px 20px;
  }
  @media (max-width: 550px) {
    margin: 10px 5px;
    font-size: 18px;
    line-height: 24px;
  }
`
const MainText = styled.div`
  margin: 50px 40px 40px;
  display: flex;
  flexDirection: column;
  alignItems: center;
  @media (max-width:550px) {
  margin: 40px 20px;
}
`
const ListAndPicWrapper = styled.div`
display: grid;
grid-template-columns: 1fr;
padding: 80px;
@media (max-width: 1100px) {
  grid-template-columns: 1fr;
  padding: 40px;
}
@media (max-width: 850px) {
  grid-template-columns: 1fr;
  padding: 40px;
}
@media (max-width:550px) {
  grid-template-columns: 1fr;
  padding: 10px;
}
`

function Home() {
  const [highResLoaded, setHighResLoaded] = useState(false)
  const [isOpen, setIsOpen] = useState(false);
  function openModal() {
    setIsOpen(true);
  }
  function closeModal(){
    setIsOpen(false);
  }

  useEffect(()=> {
    // get listings


  }, [])


  return (
    <div className="Home">
      <ModalContact style={{zIndex: 3}} openModal={openModal} closeModal={closeModal} isOpen={isOpen} />
      <Header />
      <Nav openModal={openModal}/>
      <HeroContainer>
        <HighImg highResLoaded={highResLoaded}
          onLoad={()=> setHighResLoaded(true)}
          src={HeroImage} style={{width: '100%', height: 'auto', position: 'absolute'}}/>
        <LowImg highResLoaded={highResLoaded} 
          src={HeroImageLow} style={{width: '100%', filter: 'blur(1px)'}}/>
        <HeroContent>
          <HeroTitle>Bay Area Heavy Equipment Brokerage</HeroTitle>
          <HeroSubTitle>Providing an alternative to auctions</HeroSubTitle>
          <ContactNowButton onClick={openModal}>Contact Now</ContactNowButton>
        </HeroContent>
      </HeroContainer>
      <MainText>
        <ListAndPicWrapper>
          <AboutStyledList>
            <li>CalEquipCo specializes in the advertising of trucks and machinery. We do nothing else. We dont buy low and sell high. Our goal is to give owners an alternative to sending their pieces to auctions. We spend almost $100k per year to advertise your pieces on all the leading platforms. This leads to the max exposure.</li>
            <li>We take all the pictures, research pricing, handle negotiations with buyers and facilitate financing and shipping all the way through securely closing the transaction with the proper paperwork.</li>
            <li>We are a small company. This is a benefit for our sellers because we are flexible and responsive. We are also very fast. Buyers feel more confident knowing we have seen the machinery in person and are advertising it accurately. We are a corporation but we are not corporate.</li><li>It is our goal to continue to establish ourselves as the most trusted partner for owners to use when getting rid of equipment.</li><li>To date we have sold over 5.2M worth of equipment and trucks.</li><li>We look forward to building long term lasting relationships with the many owners and operators of machinery and trucks throughout the Bay Area.</li>
          </AboutStyledList>
        </ListAndPicWrapper>
      </MainText>
      <div style={{margin: '40px', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
        <HowItWorksHeaderWrapper>
        <HowItWorksHeader>How it Works</HowItWorksHeader>
        </HowItWorksHeaderWrapper>
        <StyledHowItWorks>
          <CardElementOne {...CardObjOne}/>
          <CardElementTwo {...CardObjTwo}/>
          <CardElementThree openModal={openModal} {...CardObjThree}/>
        </StyledHowItWorks>
      </div>
      <Footer />
    </div>
  );
}

export default Home;
