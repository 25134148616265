import React from 'react'
import  { HeaderContainer,StyledNewIcon, LogosWrapper, ContactWrapper, PhoneContact, EmailContact } from './headerElements'
import {FaPhoneAlt as PhoneIcon} from 'react-icons/fa'
import {IoMail as EmailIcon} from 'react-icons/io5'


const Header = ()=> {
  return (
    <>
      <HeaderContainer>
        <LogosWrapper to='/'>
          <StyledNewIcon />
        </LogosWrapper>
        <ContactWrapper>
          <PhoneContact>
            <PhoneIcon  style={{minWidth: '24px', width: '24px', marginRight: '5px'}}/>
            <a href='tel:4153053325'>1-415-305-3325</a>
          </PhoneContact>
          <EmailContact>
            <EmailIcon  style={{minWidth: '24px', width:'24px', marginRight: '5px'}}/>
            <a href='mailto:cjkainwilliams@gmail.com'>cjkainwilliams@gmail.com</a>
          </EmailContact>
        </ContactWrapper>
      </HeaderContainer>
    </>
  )
}

export default Header
