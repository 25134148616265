import React, {useState} from 'react';
import Modal from 'react-modal';
import styled from 'styled-components';
import ContactUs from '../../ContactUs'
import './styles.css'

//import { TopMessage, FormLabel, InputField, StyledTextArea }

const TopMessage = styled.div`
  flex: auto;
  font-size: 22px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  font-family: Hind Madurai;
  font-weight: 500;
  margin-bottom: 24px;
  p {
  margin-top: 16px;
  font-weight: 700;
  }
`
const SuccessMessageWrapper = styled.div`
display: flex;
flex-direction: column;
align-items: center;
justify-content: space-around;
`

const SuccessHeader = styled.h1`
font-family: Montserrat;
font-style: normal;
font-weight: 800;
font-size: 52px;
line-height: 95px;
text-align: center;
text-transform: uppercase;
color: #000;
`
const SuccessParagraph = styled.p`
  font-family: Hind Madurai;
  font-weight: 500;
  font-size: 24px;
  line-height: 41px;
`

const BackButton = styled.button`
background: #18A0FB;
border: 2px solid rgba(0, 0, 0, 0.2);
box-sizing: border-box;
border-radius: 7px;
width: 165px;
height: 42px;
font-family: Hind Madurai;
font-style: normal;
font-weight: normal;
font-size: 16px;
line-height: 22px;
color: #FFF;
background: #E4A436;
margin-bottom: 24px;
`

// bind to root element
Modal.setAppElement('#root')

function ModalContact({ isOpen, closeModal}) {
  //let subtitle;
  //const [modalIsOpen,setIsOpen] = React.useState(false);
  const [success, setSuccess] = useState(false)
  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    //subtitle.style.color = '#f00';
  }
  function closeAndReset(){
    closeModal()
    setSuccess(false)
  }

    return (
      <>
        <Modal
          className='modal'
          overlayClassName='modal-overlay'
          isOpen={isOpen}
          onAfterOpen={afterOpenModal}
          onRequestClose={closeAndReset}
          contentLabel="Example Modal"
        >
          { !success ?
              (
                <>
          <TopMessage>Send me a little info about what you want to sell and leave your phone number in the body of the message and I will contact you soon.
            <p> - Charlie Kain Williams</p>
          </TopMessage>
          <ContactUs closeModal={closeModal} setSuccess={setSuccess}/>
           <button onClick={closeAndReset} className='close'>x</button>
                </>
              ) :
                <SuccessMessageWrapper>
                  <SuccessHeader>Message Sent</SuccessHeader>
                  <SuccessParagraph>You can expect an email or call from me shortly</SuccessParagraph>
                  <BackButton onClick={closeAndReset}>Back</BackButton>
                </SuccessMessageWrapper>
          }
        </Modal>
      </>
    );
}
export default ModalContact
