import{SemiBoldWrapper as SB, ContactNowButton, CardContainer, CardHeader, CardBody, CardBodyContent} from './CardElements'

const CardElementOne = ({ gray, headerContent})=> { 
  return ( 
     <CardContainer gray={gray}> 
       <CardHeader gray={gray} >
         {headerContent} 
       </CardHeader>
       <CardBody>
             <CardBodyContent>
                Call my cell<SB> 415 305 3325</SB>
             </CardBodyContent>
             <CardBodyContent>
               <SB>Or contact through site</SB>
             </CardBodyContent>
       </CardBody>
      </CardContainer>
  ) 
} 

const CardElementTwo = ({ gray, headerContent})=> { 
  return ( 
     <CardContainer gray={gray}> 
       <CardHeader gray={gray} >
         {headerContent} 
       </CardHeader>
       <CardBody>
         <CardBodyContent>Take photos, make ads, and handle <SB> all prospects</SB></CardBodyContent>
       </CardBody>
      </CardContainer>
  ) 
} 

const CardElementThree = ({ gray, headerContent, openModal})=> { 
  return ( 
     <CardContainer gray={gray}> 
       <CardHeader gray={gray} >
         {headerContent} 
       </CardHeader>
       <CardBody>
             <CardBodyContent>
               I <SB>secure the transaction</SB> for <SB>buyer</SB> and <SB> seller</SB> 
             </CardBodyContent>
       </CardBody>
       <ContactNowButton onClick={openModal}>Get in Touch</ContactNowButton>
      </CardContainer>
  ) 
} 
export {CardElementOne, CardElementTwo, CardElementThree}
